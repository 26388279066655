import React from "react";
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <h3>Ⓒ Skule&trade; Archives 2022. <br/> All rights reserved.</h3>
        </div>   
    );
}

export default Footer;
